<template>
  <div class="ds-wrapper">
    <ul class="my-1">
      <li class="p-2">
        <a @click="viewRequest">View request</a>
      </li>
      <li
        class="p-2"
        v-if="showEditOption"
        @click="handleEditRequest"
      >
        Edit request
      </li>
      <li class="p-2" @click="resend(paymentRequest)" v-if="showResendOption(paymentRequest)">
        Resend request
      </li>
      <li class="p-2" v-if="showCancelButton()" @click="cancelPopup = true">
        {{ (isRepayEnabled && isAutoDebit) ? "Repay balance" : "Cancel request" }}
      </li>
      <li class="p-2" v-if="showMarkAsPaid()" @click="markAsPaidPopup = true">Mark as paid</li>
      <li
        class="p-2"
        v-if="paymentRequest.paymentStatus === 'Pending' && !isTwoParty()"
        @click="viewPaymentPage(paymentRequest.paymentRequestId)"
      >
        View payment page
      </li>
      <li
        class="p-2"
        v-if="!['Submitted'].includes(paymentRequest.paymentStatus) && paymentRequest.selectedProduct && paymentRequest.selectedProduct.statementOfAccount && paymentRequest.selectedProduct.statementOfAccount.showInMerchantPortal"
        @click="generateStatement"
      >
        Get statement
      </li>
      <li class="p-2" v-if="showTopUpButton" @click="handleRequestFunding">
        Increase funding
      </li>
      <li class="p-2" v-if="showDisbursementButton" @click="handleDisbursementDateUpdate">
        Edit disbursement date
      </li>
    </ul>

    <!-- MARK AS PAID POP UP -->
    <vs-popup title="Mark as paid (payment received via other methods)" class="close-icon-hidden custom-popup-decline" id="decline-popup" :active.sync="markAsPaidPopup">
      <div class="w-full text-justify">
        <p class="flex mb-2">This action will change the payment request status to complete, and create a transaction for display purposes only indicating that funds have been disbursed to the nominated merchant account - are you sure you want to proceed?</p>
      </div>
      <div class="popup-action-btn-container flex justify-end">
        <vs-button size="large" @click="markAsPaid" action-btn :disabled="statusButtonDisable">Continue</vs-button>
        <vs-button color="danger" @click="markAsPaidPopup = false" size="large" action-btn class="ml-6 md:ml-10" :disabled="statusButtonDisable">Cancel</vs-button>
      </div>
    </vs-popup>
    <!-- MARK AS PAID POP UP -->

    <!-- CANCEL AND REPAY POP UP -->
    <vs-popup class="close-icon-hidden custom-popup-decline" :title="isRepayEnabled ? 'Repay balance' : 'Cancel request'" :active.sync="cancelPopup">
      <div v-if="isRepayEnabled && isAutoDebit">
        <p>Are you sure - proceeding will trigger an immediate direct debit on your account for the outstanding balance on this request, which can take 2-3 days to complete (make sure your bank account supports direct debit, otherwise you may incur fees for a dishonoured payment).</p>
        <p class="mt-2">If you wish to pay out the agreement immediately, please EFT the funds to {{ appName }} instead (typically processes within minutes).</p>
      </div>
      <div v-else-if="isRepayEnabled">
        <p>Please contact us via support channels to cancel this request</p>
      </div>
      <div v-else>
        <p>Are you sure you want to cancel this payment request?</p>
      </div>
      <div v-if="isRepayEnabled && isAutoDebit" class="flex my-6 custom-quillEditor legals-custom payment-legals">
        <div>
          <vs-checkbox class="mt-1" name="repayTerms" id="repayTerms" v-model="repayTerms"></vs-checkbox>
        </div>
        <div>
          <p v-html="latestDirectDebitTerms.explicitTerms"></p>
        </div>
      </div>
      <div class="popup-action-btn-container flex justify-end" v-if="isRepayEnabled && !isAutoDebit">
        <vs-button @click="hideCancelPopup" action-btn size="large">Ok</vs-button>
      </div>
      <div class="popup-action-btn-container flex justify-end" v-else>
        <vs-button @click="markPaymentAsCancelled" class="mr-5" action-btn :disabled="isRepayEnabled && !repayTerms" size="large">{{ isRepayEnabled ? "Continue" : "Yes - cancel it" }}</vs-button>
        <vs-button @click="hideCancelPopup" color="danger" size="large" action-btn>{{ isRepayEnabled ? "Cancel" : "No - don't cancel it" }}</vs-button>
      </div>
    </vs-popup>
    <!-- CANCEL AND REPAY POP UP -->
  </div>
</template>
<script>
  import Vue from "vue";
  import { mapActions } from "vuex";

  export default {
    props: ["index", "getPaymentRequestList", "paymentRequest", "showDropDown"],

    data() {
      return {
        appName: process.env.VUE_APP_NAME == "RelloPay" ? "Rello" : "FlipPay",
        cancelPopup: false,
        markAsPaidPopup: false,
        latestDirectDebitTerms: {},
        repayTerms: false,
        statusButtonDisable: false,
      }
    },

    methods: {
      ...mapActions("paymentRequest", ["cancelPaymentRequest", "getStatement", "resendPaymentRequest", "updatePaymentRequest"]),
      ...mapActions("legals", ["fetchTermsAndConditions"]),

      viewRequest() {
        const routeData = this.$router.resolve({
          name: "staff-payment-requests-detail",
          params: { id: this.paymentRequest._id },
        });

        window.open(routeData.href, "_blank");
      },

      handleRequestFunding() {
        this.$emit("requestTopUp", { product: this.paymentRequest.selectedProduct, _id: this.paymentRequest._id });
      },

      handleDisbursementDateUpdate() {
        this.$emit("editDisbursementDate", { paymentRequest: this.paymentRequest, _id: this.paymentRequest._id });
      },

      handleEditRequest() {
        this.$emit("editRequest", { paymentRequest: this.paymentRequest, _id: this.paymentRequest._id });
      },

      showResendOption(paymentRequest) {
        return ["Expired", "Pending"].includes(paymentRequest.paymentStatus) && (paymentRequest.payeeName || paymentRequest.payeeEmail) && !this.isTwoParty();
      },

      isTwoParty() {
        return (this.paymentRequest && this.paymentRequest.selectedPaymentPlan && this.paymentRequest.selectedPaymentPlan.currentPlan && this.paymentRequest.selectedPaymentPlan.currentPlan.productConfigType == "TWO_PARTY");
      },

      showMarkAsPaid() {
        let showOption = false;

        if (this.paymentRequest.paymentStatus == "Pending") {
          if (this.paymentRequest.requestOptions.includes("pay-now") && this.paymentRequest.requestOptions.length == 1) {
            showOption = true;
          } else {
            const product = this.paymentRequest.selectedPaymentPlan || this.paymentRequest.payLaterPlan[0];

            if (product && product.currentPlan && product.currentPlan.productConfigType && (product.currentPlan.productConfigType === "THREE_PARTY") && product.currentPlan.isPayNow) {
              showOption = true;
            }
          }
        }

        return showOption;
      },

      async resend(paymentRequest) {
        let data = {
          id: paymentRequest._id,
          data: { flipAdminId: this.user.adminId ? this.user.adminId : null, sendOnlyComms: paymentRequest.paymentStatus == "Pending" },
        };

        this.$vs.loading();

        return await this.resendPaymentRequest(data).then((response) => {
          if (response.data) {
            this.$vs.notify({
              color: "success",
              title: "Payment request",
              text: "Payment request resent successfully.",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Payment request",
              text: "We are unable to process your request at this time.",
            });
          }
        }).catch((ex) => {
          this.$vs.notify({
            color: "danger",
            title: "Payment request",
            text: "We are unable to process your request at this time.",
          });
        }).finally(() => {
          this.$vs.loading.close();
          this.showDropDown(this.index);
        });
      },

      viewPaymentPage(paymentRequestId) {
        const paymentPageUrl = `${process.env.VUE_APP_ROOT_API}${paymentRequestId}`;
        window.open(paymentPageUrl, "_blank");
      },

      async markAsPaid() {
        this.statusButtonDisable = true;

        const data = {
          id: this.paymentRequest._id,
          processedByAdmin: null
        };

        this.$vs.loading();

        return await this.updatePaymentRequest(data).then((response) => {
          this.$vs.loading.close();

          if (response.data) {
            this.showToastMessage("Payment request", "Payment request completed successfully", "success");
          } else {
            this.showToastMessage("Payment request", "Payment request update error", "error");
            this.statusButtonDisable = false;
          }

          this.markAsPaidPopup = false;
          this.getPaymentRequestList();
        }).catch((ex) => {
          this.$vs.loading.close();
          this.statusButtonDisable = false;
          this.showToastMessage("Payment request", "We are unable to process your request at this time", "error");
          this.markAsPaidPopup = false;
        });
      },

      addPopUpClass(enabled) {
        if (enabled) {
          document.body.classList.add("custom-popup", "decline-popup");
        } else {
          document.body.classList.remove("custom-popup", "decline-popup");
        }
      },

      // CANCEL PR STARTS

      showCancelButton() {
        let status = false;

        if (["Info-required", "Pending", "Submitted"].includes(this.paymentRequest.paymentStatus)) {
          status = true;
        } else if (this.paymentRequest.requestType === "one-off" && this.paymentRequest.requestOptions.includes("pay-now") && this.paymentRequest.paymentStatus === "Expired") {
          status = true;
        } else if (!this.isEarlyPayout && this.paymentRequest.paymentStatus === "Active" && (this.isTwoParty() || this.showDisbursementButton)) {
          status = true;
        }

        return status;
      },

      getDirectDebitLegals() {
        this.fetchTermsAndConditions().then((response) => {
          const allTerms = response.data.data;
          const privacyTerms = allTerms.privacy;
          const websiteTerms = allTerms.websiteTerms;

          this.latestDirectDebitTerms = allTerms.directDebitTerms;
          this.latestDirectDebitTerms.explicitTerms = this.replaceLegalContent(
            this.latestDirectDebitTerms.explicitTerms,
            {
              fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.latestDirectDebitTerms._id}?type=DIRECT_DEBIT&fullDocument=true" target="_blank">${this.latestDirectDebitTerms.documentName}</a>`,
              privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${privacyTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${privacyTerms.documentName}</a>`,
              generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${websiteTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${websiteTerms.documentName}</a>`,
            }
          );
        });
      },

      hideCancelPopup() {
        this.cancelPopup = false;
        this.repayTerms = false;
      },

      async markPaymentAsCancelled(values) {
        if (this.isRepayEnabled && !this.repayTerms) {
          return false;
        }

        let data = {
          id: this.paymentRequest._id,
          data: { flipAdminId: this.user.adminId ? this.user.adminId : null },
        };

        if (this.isRepayEnabled) {
          data.data.type = "repay";
          data.data.repayTerms = this.repayTerms;
          data.data.paymentMethodTerms = this.latestDirectDebitTerms._id;
        }

        this.$vs.loading();
        await this.cancelPaymentRequest(data).then(result => {
          this.$vs.loading.close();
          this.cancelPopup = false;
          this.repayTerms = false;
          this.$vs.notify({
            color: "success",
            title: result.data.title || "Payment request",
            text: result.data.message,
          });
          this.getPaymentRequestList();
        }).catch(ex => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Payment request",
            text: ex.response.data.message,
            fixed: true
          });
        });
      },

      // CANCEL PR ENDS

      // GET STATEMENT STARTS
      async generateStatement() {
        this.$vs.loading();
        await this.getStatement(this.paymentRequest.paymentRequestId).then(result => {
          window.open(result.data.data, "_blank");
        }).catch(ex => {
          this.$vs.notify({
            color: "danger",
            title: "Statement",
            text: "Failed to get the statement. Please try again later.",
          });
        });

        this.$vs.loading.close();
        this.getPaymentRequestList();
      },

      // GET STATEMENT ENDS
    },

    computed: {
      user() {
        return this.$store.state.AppActiveUser;
      },

      partnerId() {
        return ["admin", "superadmin"].includes(this.user.userType.toLowerCase()) ? this.user._id : this.user.partnerId;
      },

      isEarlyPayout() {
        let isEarlyPayout = false;

        if ( this.paymentRequest && this.paymentRequest.transactions && this.paymentRequest.transactions.find(item => !item.deleted && (item.type === "Payment") && (item.subType === "Early Payout") && (item.status === "Processing"))) {
          isEarlyPayout = true;
        }

        return isEarlyPayout;
      },

      isRecurring() {
        return (this.paymentRequest.requestType === "recurring");
      },

      isAutoDebit() {
        return (this.paymentRequest && this.paymentRequest.selectedPaymentPlan && this.paymentRequest.selectedPaymentPlan.currentPlan && this.paymentRequest.selectedPaymentPlan.currentPlan.autoDebit);
      },

      isDisbursed() {
        return (this.paymentRequest && this.paymentRequest.disbursementTransactions && this.paymentRequest.disbursementTransactions && this.paymentRequest.disbursementTransactions.status == "Disbursed");
      },

      isRepayEnabled() {
        const enabled = this.isTwoParty() && this.isDisbursed && (this.paymentRequest.paymentStatus === "Active");

        if (enabled) {
          this.getDirectDebitLegals();
        }

        return enabled;
      },

      showTopUpButton() {
        return (this.paymentRequest.paymentStatus == "Active" && this.paymentRequest && this.paymentRequest.selectedProduct && this.paymentRequest.selectedProduct.merchantTopUp && this.paymentRequest.selectedProduct.merchantTopUp.isEnabled);
      },

      showDisbursementButton() {
        const allowDisbursementDateOverride = (this.paymentRequest && this.paymentRequest.productCurrentConfig && this.paymentRequest.productCurrentConfig.allowDisbursementDateOverride);
        const disbursedTransaction = this.paymentRequest.transactions.find(item => item.type === "Disbursement" && ["Disbursed", "Processing", "Queued"].includes(item.status));

        return (["Active", "Pending", "Submitted"].includes(this.paymentRequest.paymentStatus) && allowDisbursementDateOverride && !disbursedTransaction);
      },

      showEditOption() {
        return (this.paymentRequest.paymentStatus == "Pending" && !this.isTwoParty());
      }
    },

    watch: {
      markAsPaidPopup(val) {
        this.addPopUpClass(val);
      },

      cancelPopup(val) {
        this.addPopUpClass(val);
      }
    },
  }
</script>

<style lang="scss">
  .dropbtn {
    border: none;
    border-radius: 4px;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }

  .dropdown-content {
    display: block;
    position: absolute;
    min-width: 160px;
    overflow: hidden;
    left: -145px;
    top: 30px;
    z-index: 9999;
    background: #ffffff;
    /* Medium grey */

    border: 0.5px solid #bdbdbd;
    /* Card shadow */

    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;

    a {
      padding: 8px;
      text-decoration: none;
      display: block;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 132.3%;
      color: #757575 !important;

      &:hover {
        background: #eaf2ff;
      }
    }

    ul {
      li:not(:last-child) {
        border-bottom: 1px solid #dfdfdf !important;
      }
    }
  }

  span {
    vertical-align: middle;
  }

  .dropdown-selected {
    background: #eaf2ff;
    span {
      color: #05053f !important;
      @apply font-medium;
    }
  }
  </style>